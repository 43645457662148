class Hiraya {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){

    const swiper = new Swiper(".swiper-gallery01", {
      spaceBetween: 10,
      slidesPerView: 7,
      loop:true,

    });

    const swiper02 = new Swiper(".swiper-main01", {
      thumbs: {
        swiper: swiper
      },
      slidesPerView: 1,
      spaceBetween: 10,
    });

    const swiper03 = new Swiper(".swiper-gallery02", {
      spaceBetween: 10,
      slidesPerView: 7,
      loop:true,
    });

    const swiper04 = new Swiper(".swiper-main02", {
      thumbs: {
        swiper: swiper03
      },
      slidesPerView: 1,
      spaceBetween: 10,
    });

  }
}
