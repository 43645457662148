class SwiperEvent {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){
    if($(".underSwiper .swiper-slide").length > 1) {
      var swiper = new Swiper(".underSwiper", {
        spaceBetween: 20,
        speed: 500,
        slidesPerView: 1,
        centeredSlides: true,
        loop: true,
        pagination: {
          el: ".swiper-pagination"
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      });
    }
    var swiper = new Swiper(".customer-Swiper", {
      autoplay: {
        delay: 4000,
      },
      speed: 1500,
      slidesPerView: 1.2,
      centeredSlides: true,
      spaceBetween: 20,
      breakpoints: {
        // スライドの表示枚数：500px以上の場合
        960: {
          centeredSlides: false,
          slidesPerView: 1.7,
        }
      },
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
        renderBullet: function(index, className) {
          //中の文字を表示
          return '<span class="' + className + '">' + ['お問い合わせ','いえづくりについて','仮プラン提案・資金計画書','ローンシュミレーション','土地物件探し＆ご案内','住宅設備ショールーム','ベストプランのご提案','買付証明書の提出','土地のご契約','建物の契約',][index] + '</span>';　 }
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    });

  }
}
