class SimpleAccordion {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){
      $(".js-simple-acc").on("click", function() {
      $(this).toggleClass("is-active");
      $(this).next().slideToggle();
    });
  }
}
