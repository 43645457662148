class Contact {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){
    let exhibition_hall = document.querySelectorAll("[name='来場展示場'] option");
    let exEl = $(".exhibition_hall");
    if(exhibition_hall) {
      exhibition_hall.forEach((ele) => {
        if(ele.value == this.getParam('exhibition_hall')) {
          ele.setAttribute('selected', 'selected');
          exEl.find('label').css({'display': 'none'})
          exEl.append($(`<div>${this.getParam('exhibition_hall')}</div>`));
          exEl.css({'display': 'flex', 'align-items': 'flex-end'});
        }
      })
    }

    let minDate;

    if($(".p-online-counter").length) {
      minDate = 5;
      console.log(minDate);
    } else {
      minDate = 2;
      console.log(minDate);
    }

    $('.datepicker').datepicker({
      dateFormat: 'yy/mm/dd',
      monthNames: [ "1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月" ],
      minDate: minDate
    }); 
  }
  getParam(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
}
