class Header {
  constructor(el, opt){
    this.event();
  }
  event() {
    $('.js-drawer').on({
      click: function() {
        $('.js-header').toggleClass('is-acitve');
        $('html').toggleClass('no-scroll');
      }
    })
    // let hList = document.querySelectorAll('.js-hList a');
    // let hList_num = 0;
    
    // hList.forEach(el => {
    //   if(el.getAttribute('href').indexOf(location.pathname.split('/')[3]) > 0) {
    //     el.parentNode.classList.add('is-active');
    //     hList_num++;
    //   }
    // });
    // if(!hList_num) {
    //   hList[0].parentNode.classList.add('is-active');
    // }
  }
};