window.addEventListener('DOMContentLoaded', () => {
	$('.es-gallery__loop').modaal({
    type: 'image'
  });

  $('.live-modaal').modaal({
    type: 'image'
  });
});

