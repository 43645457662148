class WorksSwiper {
  constructor(el, opt){
    this.event();
  }
  event() {
    if (window.matchMedia('(max-width: 979px)').matches) {
      var swiper = new Swiper(".works-achieve-swiper", {
        autoplay: {
          delay: 2000,
        },
        speed: 1500,
        slidesPerView: 1.1,
        spaceBetween: 20,
        loop: true,
        centeredSlides: true,
      });
    }
  }
};