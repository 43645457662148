class TagAccordion {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){
    $(this.el).on("click", function() {
      $(this).toggleClass("is-active");
      $(".js-tagAco-ele").slideToggle();
    });
  }
}
