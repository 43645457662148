class Top {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){
    var swiper = new Swiper(".mySwiper", {
      autoplay: {
        delay: 4000,
      },
      speed: 1500,
      slidesPerView: 1.3,
      spaceBetween: 30,
      centeredSlides: true,
      loop: true,
    });

    var swiper = new Swiper(".mySwiper_sp", {
      autoplay: {
        delay: 4000,
      },
      speed: 1500,
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      loop: true,
    });
    var swiper = new Swiper(".newsTicker", {
      slidesPerView: 1,
      spaceBetween:15,
      direction: "vertical",
      autoplay: {
        delay: 3000,
      },
      speed: 1500,

      loop: true,
    });
    var swiper = new Swiper(".newsTicker_sp", {
      slidesPerView: 1,
      spaceBetween:15,
      directiona: "vertical",
      autoplay: {
        delay: 3000,
      },
      speed: 1500,

      loop: true,
    });



  }
}
