class Select {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){
    $(function(){
      $('select').on('change', function(){
          if($(this).val() == "placeholder"){
              $(this).css('color','rgba(78, 93, 80, 0.4);')
          } else {
              $(this).css('color','#4E5D50')
          }
      });
  });
  }
}
