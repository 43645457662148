window.addEventListener("DOMContentLoaded", () => {
  class Accordion {
    constructor(AcBtns, AcDatas){
      this.isResize = true;
      this.w = window.innerWidth;
      this.HeightArray = [];
      this.AcBtns = AcBtns;
      this.AcDatas = AcDatas;

      this.onSwitch = this.onSwitch.bind(this);
      this.init();
    }

    setStyle(){
      this.AcDatas.forEach(ele => {
        let h = ele.children[0].clientHeight
        ele.style.height = 0;

        this.HeightArray.push(h);
      });
    }

    onAllSwitch(){
      this.AcBtns.forEach(ele => {
        var parent = ele.parentNode;
        var next = ele.nextElementSibling;
        if (parent.classList.contains("-open")) {
          next.style.height = 0;
          parent.classList.remove("-open");
        }
      });
    }

    onSwitch(ele, i){
      var parent = ele.parentNode;
      var next = ele.nextElementSibling;

      if (parent.classList.contains("-open")) {
        this.onAllSwitch();
        next.style.height = 0;
        parent.classList.remove("-open");
      } else {
        this.onAllSwitch();
        parent.classList.add("-open");
        next.style.height = this.HeightArray[i] + "px";
      }
    }

    onResize(){
      this.HeightArray.length = 0;

      this.AcDatas.forEach(ele => {
        let h = ele.children[0].clientHeight
        if (ele.parentNode.classList.contains("-open")) ele.style.height = h + "px";
        this.HeightArray.push(h);
      });
      this.w = window.innerWidth;
    }

    init(){
      this.setStyle();

      window.addEventListener("resize", e => {
        if (this.isResize) {
          requestAnimationFrame(() => {
            this.isResize = true;
            if (this.w != window.innerWidth) {
              this.onResize();
            };
          });
          this.isResize = false;
        };
      });

      this.AcBtns.forEach((ele, i) => {
        ele.addEventListener("click", e => {
          this.onSwitch(ele, i);
        });
      });
    }
  }

  const AcBtns = document.querySelectorAll(".q-ac-btn");
  const AcDatas = document.querySelectorAll(".q-ac-data")
  new Accordion(AcBtns, AcDatas);
});