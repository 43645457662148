class MegaMenu {
  constructor(el, opt) {
    this.el = el;
    if($(this.el).length){
      this.event();
    }
  }
  event(){

    $(function() {

      // ①マウスをボタンに乗せた際のイベントを設定
      $('.mega-hov').hover(function() {


        // ②乗せたボタンに連動したメガメニューをスライドで表示させる
        $(this).find('.menu-contents').stop().fadeIn();

      // ③マウスをボタンから離した際のイベントを設定
      }, function() {

        // ④マウスを離したらメガメニューをスライドで非表示にする
        $(this).find('.menu-contents').stop().fadeOut();

      });

    });

  }
}
